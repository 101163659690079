<template>
	<div>
		<s-crud
			:filter="filter"
			:config="config"
			search-input
			title="Listado de Lotes Calibrados"
			height="auto"
			no-full
		>
			<template v-slot:filter>
				<v-container>
					<v-row justify="center">
						<v-col cols="6" md="4" lg="3">
							<s-date
								label="Fecha Inicio"
								v-model="filter.cDateInitial"
							></s-date>
						</v-col>
						<v-col cols="6" md="4" lg="3">
							<s-date
								label="Fecha Fin"
								v-model="filter.cDateFin"
							></s-date>
						</v-col>
						<v-col cols="12" md="4" lg="3">
							<s-select-definition
								:def="1230"
								label="Planta Procesadora"
								v-model="filter.TypeProcessingPlant"
								clearable
							/>
						</v-col>
					</v-row>
					<v-row justify="center">
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1172"
								label="Tipo Cultivo"
								v-model="filter.TypeCrop"
							/>
						</v-col>
						<v-col cols="6" md="4" lg="3">
							<s-select-definition
								clearable
								:def="1173"
								label="Cultivo"
								v-model="filter.TypeCultive"
							/>
						</v-col>

						<v-col cols="12" md="4" lg="3">
							<s-select-variety
								clearable
								:cultiveID="filter.TypeCultive"
								label="Variedad"
								full
								v-model="filter.VrtID"
							/>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:RcfState="{ row }">
				<v-chip x-small :color="row.TypeStateColor">
					{{ row.TypeStateName }}
				</v-chip>
			</template>

			<template v-slot:TypeAlertName="{ row }">
				<v-chip x-small :color="row.TypeAlertColor">
					{{ row.TypeAlertName }}
				</v-chip>
			</template>

			<template v-slot:ViewCert="{ row }">
				<v-btn x-small color="info" block outlined @click="clickView(row)">
					<v-icon style="font-size: 16px !important">fas fa-eye</v-icon>
				</v-btn>
			</template>

			<template v-slot:LdhWeightExportable="{ row }">
				<v-chip small color="info">
					{{ row.LdhWeightExportable }}
				</v-chip>
			</template>

			<template v-slot:LdhSampled="{ row }">
				<v-chip x-small :color="row.LdhWeightExportable == null  ? 'error': 'success'">
					{{ row.LdhWeightExportable == null  ? 'No': 'Si' }}
				</v-chip>
			</template>

			
		</s-crud>

		<v-dialog
			v-if="openDialogView"
			v-model="openDialogView"
			persistent
			style="display: contents"
			width="70%"
		>
			<s-certification-lot :FltID="FltID" @close="openDialogView=false" />
		</v-dialog>


	</div>
</template>

<script>
	/* import _sReceptionFresh from "@/services/FreshProduction/ReceptionFreshService"; */

	//Service
	import _sDiscartDamage from "../../../services/FreshProduction/PrfLotDiscartDamageService.js";

	//Components
	import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
	import SCertificationLot from "@/components/TecnicalAssistence/sCertificationLot.vue";


	export default {
		components: {
			
			SSelectVariety,
			SCertificationLot,
		},

		data() {
			return {
				openDerive: false,
				disableButtonSampling: true,
				lotProduction: [],
				dialogDamage: false,
				FltID: 0,
				openDialogView: false,
				filter: {},
				config: {
					service: _sDiscartDamage,
					model: {
						RcfID: "ID",
						RcfDateReceptionSurveillance: "date",
						RcfState: "",
						ViewCert: "",
						TypeAlertName: "",
						LdhWeightExportable: "",
						LdhTransfer: "0",
						LdhSampled: "",
					},
					headers: [
						{ text: "View", value: "ViewCert", width: 70 },
						{ text: "Estado", value: "RcfState", width: 20 },
						{ text: "Muestreo daños", value: "LdhSampled", width: 100, align: "center"},
						{ text: "Nro. Lote.", value: "RcfID", width: 100 },
						{
							text: "Fec. Recep. Vigilancia.",
							value: "RcfDateReceptionSurveillance",
							width: 140,
						},
						{
							text: "Tipo Servicio",
							value: "TypeServiceName",
							width: 100,
						},
						{ text: "Productor", value: "PrdCardName", width: 360 },
						{ text: "Productor Responsable x Cobrar", value: "PrdCardNameResponsable", width: 360 },
						{ text: "Fundo", value: "FagName", width: 260 },
						{ text: "Lote", value: "FltName", width: 100 },
						{ text: "Tipo Cultivo", value: "TypeCropName", width: 160 },
						{ text: "Cultivo", value: "TypeCultiveName", width: 160 },
						{ text: "Variedad", value: "VrtName", width: 160 },
						{ text: "Cod. Senasa", value: "FltCodSenasa", width: 160 },
						{
							text: "Guia Cosecha",
							value: "RcfNumberHarvestGuide",
							width: 160,
						},
						{
							text: "Cant. Fruta",
							value: "AmountJabasWeigh",
							width: 160,
						},

						{
							text: "Peso Muestreado (KG)",
							value: "WbbGrossWeight",
							width: 160,
						},
						{
							text: "Peso Promedio Fruta (KG)",
							value: "WbbWeightAverage",
							width: 160,
						},
						{
							text: "Peso Total Lote (KG)",
							value: "WbbNetWeight",
							width: 160,
						},
						{
							text: "Peso Exportable (KG)",
							value: "LdhWeightExportable",
							width: 160,
						},

						{
							text: "Peso Descarte (KG)",
							value: "LdhDiscartTotal",
							width: 160,
						},

						{
							text: "Planta Procesdora",
							value: "TypeProcessingPlantName",
							width: 160,
						},
						{ text: "Empacadora", value: "TypeBalerName", width: 160 },
						{
							text: "Zona Ubicacion",
							value: "TypeZonLocationName",
							width: 160,
						},
						{ text: "Alerta", value: "TypeAlertName", width: 100 },
						{
							text: "Observaciones",
							value: "RcfObservations",
							width: 260,
						},
					],
				},
			};
		},

		methods: {

			clickView(val) {
				this.FltID = val.FltID;
				this.openDialogView = true;
			},

		},
	};
</script>
